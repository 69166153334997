.colorcard {
    border-left: 16px solid var(--card-color);
}

.card {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin: 16px;
}

.card:not(:last-child) {
    margin-bottom: 0;
}

.card__content {
    padding: 16px;
}
