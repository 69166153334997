.customButton {
    background-color: #E0E0E0;
    border: none;
    position: relative;
    color: black;
    width: 100%;
    height: 48px;
    font-weight: 700;
    font-size: 1.5em;
    text-align: left;

    &.active::after {
        content: '';
        position: absolute;
        top: -4px;
        left: -15px;
        width: calc(100% + 19px);
        height: calc(100% + 8px);
        background-color: black;
        z-index: 0;
    }

    span {
        position: relative;
        z-index: 2;
    }

    .customButton__active_check {
        // left middle
        position: absolute;
        top: calc(50% - 7px);
        left: -14px;
        width: 13px;
        height: 13px;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.customButton>.customButton__fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #E0E0E0;
    z-index: 1;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: var(--fill-width, 100%);
        background-color: #FF97AA;
    }
}
