.list-item {
    height: 66px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 16px;

    .title {
        font-weight: 600;
        width: 100%;
        font-size: 2em;
    }

    .score {
        margin-right: 20px;
    }

    .right {
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 8px;

        .name {
            font-size: 1.3em;
            font-weight: 500;
        }

        .detail {
            display: flex;
            justify-content: space-between;
        }
    }
}

.list-card {
    .card__content {
        height: 100%;
        padding: 0;
        overflow-y: auto;
        position: relative;
        // scrollbar-gutter: stable;
        // /* Customize website's scrollbar like Mac OS
        // Not supports in Firefox and IE */

        /* total width */
        &::-webkit-scrollbar {
            background-color: transparent;
            width: 16px;
            position: absolute;
            right: 16px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        /* scrollbar itself */
        &::-webkit-scrollbar-thumb {
            background-color: #babac0;
            border-radius: 16px;
            border: 4px solid #fff;
        }

        &::-webkit-scrollbar-button {
            display: none;
        }
    }
}

.bottom {
    flex: 1 1 auto;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .card__content {
        flex: 1 1 auto;
        overflow-y: auto;
        position: relative;

        &::-webkit-scrollbar {
            background-color: transparent;
            width: 16px;
            position: absolute;
            right: 16px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        /* scrollbar itself */
        &::-webkit-scrollbar-thumb {
            background-color: #babac0;
            border-radius: 16px;
            border: 4px solid #fff;
        }

        &::-webkit-scrollbar-button {
            display: none;
        }
    }
}

.tag {
    font-size: 1.3em;
    width: 40px;
    border-radius: 20px;
    text-align: center;
    font-weight: 500;

    &.large {
        font-size: 2.5em;
        padding: 6px;
        width: 80px;
        border-radius: 40px;
        text-align: center;
        font-weight: 700;
    }
}
