.roundtag {
    font-weight: 500;
    font-size: 18px;
    display: flex;
}

.roundtag__label {
    background-color: var(--color);
    padding: 5px 10px;
}

.roundtag__round {
    background-color: var(--color);
    display: inline-block;
    border-radius: 0 17px 17px 0;
    width: 34px;
    height: 34px;
    z-index: 2;
    position: relative;
}

.roundtag:not(:first-child) .roundtag__label {
    position: relative;
    z-index: 1;
    margin-left: -34px;
    padding-left: 49px;
}
