.table-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0;
}

tbody:before {
  content: '';
  display: block;
  height: 12px;
}

tbody:after {
  content: '';
  display: block;
  height: 12px;
}
